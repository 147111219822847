<template>
  <v-card class="my-2" @click="goTo">
    <v-row no-gutters>

      <!-- Label -->
      <v-col cols="12">
        <v-row no-gutters justify="space-between">
          <div class="font-weight-bold subtitle-1 mx-1 primary-text-color">{{data.addressTo}}</div>

          <v-menu
            :nudge-width="200"
            offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                width="48"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-card rounded>
              <v-list>
                <v-list-item @click="itineraryFrom">{{$t('view.history.buttons.itineraryFrom')}}</v-list-item>
                <v-list-item @click="itineraryTo">{{$t('view.history.buttons.itineraryTo')}}</v-list-item>
                <v-list-item @click="deleteSearchHistory(data._id)">{{$t('view.history.buttons.delete')}}</v-list-item>

              </v-list>
            </v-card>
          </v-menu>
        </v-row>
      </v-col>

      <v-col cols="12">
        <div class="ma-1">{{$moment(data.date).fromNow()}}</div>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MyHistoryCard",

  props: {
    data: {type: Object, default: {}}
  },

  methods: {
    itineraryFrom() {
      if (this.$route.name === 'Itinerary')
        this.$router.replace({query:  {...this.$route.query, from: `${this.data.coordinatesTo.lat},${this.data.coordinatesTo.lng}`}});
      else
        this.$router.replace({name: "Itinerary", query:  {...this.$route.query, from: `${this.data.coordinatesTo.lat},${this.data.coordinatesTo.lng}`}});
    },

    itineraryTo() {
      if (this.$route.name === 'Itinerary')
        this.$router.replace({query: {...this.$route.query, to: `${this.data.coordinatesTo.lat},${this.data.coordinatesTo.lng}`}});
      else
        this.$router.replace({name: "Itinerary", query:  {...this.$route.query, to: `${this.data.coordinatesTo.lat},${this.data.coordinatesTo.lng}`}});
    },

    deleteSearchHistory(id) {
      this.$http
        .delete(`/map/users/${this.$session.get('id')}/search-history/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.$store.commit("alert/showSuccess", this.$t('view.history.success_deleted'));
          this.$emit("delete");
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
    },

    goTo() {
      if (this.data && this.data.coordinatesTo.lat) {
        this.$router.push({
          name: 'Home',
          query: {coords: `${this.data.coordinatesTo.lat},${this.data.coordinatesTo.lng}`, zoom: 13}
        })
      } else
        this.$router.replace({name: 'Home', query: {q: this.data.label}});
    }
  }
}
</script>

<style scoped>

</style>